import * as React from "react";
import { useState } from "react";
import { Input, Button, Field, createLightTheme, createDarkTheme } from "@fluentui/react-components";
import styles from "./Login.module.scss";
import { getToken } from "../../api/sso";
import {  FluentProvider } from "@fluentui/react-components";
import type { BrandVariants, Theme } from "@fluentui/react-components";
import lightTheme from "../..";


export const LoginForm: React.FC = (props: any) => {
    console.log(props)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoginError, setIsLoginError] = useState(false);

    const handleSubmit = async () => {
        // Implement login logic here
        try {
            setIsLoginError(false);
            const response = await getToken(email, password);
            window.localStorage.setItem("RYZE_TOKEN", response.tokenGeneration.accessToken);
            window.localStorage.setItem("RYZE_USER", JSON.stringify(response));
            window.location.href = "/#chat";
        } catch {
            setIsLoginError(true);
        }
    };

    const handleRequestAccess = () => {
        // Implement access request logic here
        console.log("Requesting access...");
    };
    
    return (
        <FluentProvider theme={lightTheme} className={styles.FluentProviderOverrides}>
            <div className={styles.root}>
                <div className={styles.container}>
                    <div className={styles.Inputs}>
                        <div className={styles.Logo}>
                            <img width="300px" src="https://fulcrumdigital.com/wp-content/uploads/2021/08/fd-logo-2021-1.svg"></img>
                        </div>
                        <div className={styles.Username}>
                            <Field label="Username">
                                <Input onChange={e => setEmail(e.target.value)} />
                            </Field>
                        </div>
                        <div className={styles.Password}>
                            <Field label="Password">
                                <Input type="password" onChange={e => setPassword(e.target.value)} />
                            </Field>
                        </div>
                    </div>
                    <div className={styles.Buttons}>
                        <div className={styles.LoginButton}>
                            {isLoginError && (
                                <div className={styles.ErrorMessage}>
                                    <span>Current credentials are not valid.</span>
                                </div>
                            )}
                            <Button appearance="primary" type="submit" value="" onClick={handleSubmit}>
                                Login
                            </Button>
                            <Button appearance="primary" value="Request Access" onClick={handleRequestAccess}>
                                Request Access
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </FluentProvider>
    );
};
export default LoginForm;
