import React, { useState } from "react";
import { Modal, IconButton, IIconProps, Checkbox, PrimaryButton, Stack, Label, IStackTokens, MessageBarType } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FluentProvider } from "@fluentui/react-components";
import lightTheme from "../..";
import { getUploadSettings } from "../../api";
import Loader from "../../assets/loader.gif";
import { useMessages } from "../NotificationBar";
const RYZEREADER_URI = import.meta.env.VITE_RYZEREADER_URI != null ? import.meta.env.VITE_RYZEREADER_URI : window.location.origin;

// Styling for the component
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch"
    },
    header: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "12px 12px",
        borderBottom: "1px solid #EEE"
    },
    body: {
        flex: "4 4 auto",
        padding: "10px 24px",
        overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 }
        }
    }
});

const stackTokens: IStackTokens = { childrenGap: 20 };

// Close icon for the modal
const cancelIcon: IIconProps = { iconName: "Cancel" };

const UploadDialogModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
    const [isPublic, setIsPublic] = useState(false);
    const [files, setFiles] = useState(Array<File>);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [progressMessage, setProgressMessage] = useState<string>("");
    const { addMessage } = useMessages();

    // Function to handle file selection
    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setFiles([]);
        if (file) {
            files.push(file);
            setFiles(files);
        }
    };
    const handleUpload = async (event: any) => {
        try {
            setProgressMessage("In Progress...");
            setInProgress(true);
            const file = files[0];
            const uploadSettings = getUploadSettings();
            if (file) {
                let formData = new FormData();
                formData.append("file", file);
                let response = await fetch(
                    `${RYZEREADER_URI}/projects/${uploadSettings?.ProjectID}/documents/ryze-upload?&username=${uploadSettings?.Username}&isPublic=${isPublic}`,
                    {
                        method: "POST",
                        body: formData
                    }
                );
                setFiles([]);
                const contentLength = response.headers.get("content-length") ?? "0"; // Provide a default value
                const total = parseInt(contentLength, 10);
                let loaded = 0;

                const res = new Response(
                    new ReadableStream<Uint8Array>({
                        async start(controller) {
                            const reader = response.body!.getReader();
                            for (;;) {
                                const { done, value } = await reader.read();
                                if (done) break;
                                loaded += value.byteLength;
                                controller.enqueue(value);
                            }
                            controller.close();
                        }
                    })
                );
                // Process the file here
            }
            setProgressMessage("Upload complete");
            
            addMessage({
                message: 'Your file is being processed now, wait until it became available.',
                messageBarType: MessageBarType.info, // Or any logic to determine the message type
            });
                
            
            setTimeout(() => {
                onClose();
            }, 1500);
        } catch {
            setProgressMessage("Not able to upload your file now. Try again later.");
        } finally {
            setInProgress(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onClose} isBlocking={false} containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span>Upload Document</span>
                <IconButton iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={onClose} />
            </div>
            <FluentProvider theme={lightTheme}>
                <div className={contentStyles.body}>
                    <Stack tokens={stackTokens}>
                        <Label htmlFor="fileInput">Select a file</Label>
                        <input id="fileInput" type="file" onChange={handleFileSelect} />
                        <Checkbox label="Is Public" checked={isPublic} onChange={(e, checked) => setIsPublic(!!checked)} />
                        <PrimaryButton text="Upload" onClick={handleUpload} />
                        <div>
                            {inProgress ? (
                                <div style={{ display: "flex", alignItems: "center" }} className="loaderHolder">
                                    <img width={"20px"} height={"20px"} style={{}} src={Loader} />
                                    <div style={{ fontFamily: "sans-serif" }}>{progressMessage}</div>
                                </div>
                            ) : null}
                        </div>
                    </Stack>
                </div>
            </FluentProvider>
        </Modal>
    );
};

export default UploadDialogModal;
