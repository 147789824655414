import React from "react";
import { useState, useEffect } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import {
    Send28Filled,
    Mic28Filled,
    Add28Filled,
    AddCircle28Filled,
    MicOff28Filled,
    Speaker028Filled,
    Speaker016Regular,
    Speaker028Regular,
    MoreCircle28Filled
} from "@fluentui/react-icons";
//import {listening} from 'F1EnterpriseSemanticSearch\app\frontend\src\assets\listening-to-music.svg'
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import styles from "./QuestionInput.module.css";
import Loader from "../../assets/loader.gif";
import UploadDialogModal from "../UploadDialog/UploadDialog";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
    const [transcripts, setTranscripts] = useState<string>('');
  const [recording, setRecording] = useState<boolean>(false);
  const [chunks, setChunks] = useState<Blob[]>([]);

    const [uservoice, setUserVoice] = useState<string>("");
    const [speechText, setSpeechText] = useState("");
    const [byteArray, setByteArray] = useState<Array<number>>([]);
    const [convoButtonName, setConvoButtonName] = useState<string>("Start Conversation");
    const [listenMode, setListenMode] = useState<boolean>(false);
    const [waiting, setWaiting] = useState<boolean>(false);
    const [size, setSize] = useState<String>("0 Bytes");
    const [trunc, setTrunc] = useState<String>("sample.pdf");
    const [files, setFiles] = useState<Array<Object>>([]);
    const [question, setQuestion] = useState<string>("");
    const clickRef = React.useRef<HTMLInputElement>(null);
    const sendQuestionDisabled = disabled || !question.trim();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const getVoiceInput = () => {
        setListenMode(true);
        SpeechRecognition.startListening({ language: "en-IN" });
    };

    const stopListening = () => {
        setListenMode(false);
        SpeechRecognition.stopListening();
        setQuestion(transcript);
        onSend(transcript);
    };

    const recognition = new window.webkitSpeechRecognition();

    

    const onStartConversation = () => {

        useEffect(() => {
            const onStartConversation = () =>{
                const handleSpeechRecognition = () => {
                const recognition = new window.webkitSpeechRecognition();
                recognition.continuous = true;
                recognition.interimResults = true;
                recognition.onstart = () => {
                    setRecording(true);
                };
                recognition.onend = () => {
                    setRecording(false);
                };
                recognition.onresult = event => {
                    const speechResult = event.results[event.results.length - 1];
                    const speechTranscript = speechResult[0].transcript;
                    setTranscripts(prevTranscript => prevTranscript + " " + speechTranscript);
                };
                recognition.start();
            };
            handleSpeechRecognition();
            return () => {
                recognition.stop();
            };
            }
            
        }, []);
       
        useEffect(() => {
            const handleMediaRecorder = () => {
                const chunks: Blob[] = [];
                let mediaRecorder: MediaRecorder;
                navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then(stream => {
                        mediaRecorder = new MediaRecorder(stream);
                        mediaRecorder.ondataavailable = event => {
                            chunks.push(event.data);
                        };
                        mediaRecorder.onstop = () => {
                            const audioBlob = new Blob(chunks, { type: "audio/wav" });
                            const audioUrl = URL.createObjectURL(audioBlob);
                            // You can further process the audioBlob if needed
                            console.log("Audio Blob:", audioBlob);
                            console.log("Audio URL:", audioUrl);
                        };
                        mediaRecorder.start();
                    })
                    .catch(error => {
                        console.error("Error accessing microphone:", error);
                    });
                setInterval(() => {
                    if (!recording) {
                        mediaRecorder.stop();
                    }
                }, 3000);
            };
            handleMediaRecorder();
        }, [recording]);
    };

    // const onStartConversation = () => {
    //     // setConvoButtonName("End Conversation");
    //     // setListenMode(true)
    //     // SpeechRecognition.startListening({language:'en-IN'})

    //     const recognition = new window.webkitSpeechRecognition();
    //     recognition.lang = "en-US";
    //     recognition.start();

    //     recognition.onresult = (event) => {
    //         const transcript = event.results[0][0].transcript;
    //         setSpeechText(transcript);
    //         const encoder = new TextEncoder();
    //         const bytes = encoder.encode(transcript);
    //         setByteArray(Array.from(bytes));
    //     };
    //     console.log(byteArray);
    //     console.log(speechText);

    // };

    const getFileinput = () => {
        clickRef.current?.click();
    };

    const truncate = (str: String) => {
        if (str.length >= 10) {
            let trunc = str.substring(0, 7) + "..." + "pdf";
            setTrunc(trunc);
        } else {
            let trunc = str;
            setTrunc(trunc);
        }
        return str.length > 10 ? str.substring(0, 7) + "..." + "pdf" : str;
    };

    const formatBytes = async (bytes: any, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        let size = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
        setSize(size);
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const setFilesArray = async (e: any) => {
        try {
            let file = e.target.files[0];
            //console.log(file);
            setWaiting(true);
            let formData = new FormData();
            console.log(e);
            setFiles([file]);
            formatBytes(file.size);
            truncate(file.name);
            formData.append("file", file);
            let response = await fetch("/prepdocs/ingest", {
                method: "POST",
                body: formData
            });
            const contentLength = response.headers.get("content-length") ?? "0"; // Provide a default value
            const total = parseInt(contentLength, 10);
            let loaded = 0;

            const res = new Response(
                new ReadableStream<Uint8Array>({
                    async start(controller) {
                        const reader = response.body!.getReader();
                        for (;;) {
                            const { done, value } = await reader.read();
                            if (done) break;
                            loaded += value.byteLength;
                            controller.enqueue(value);
                        }
                        controller.close();
                        setWaiting(false);
                    }
                })
            );
            setWaiting(false);
        } catch (e) {
            console.log(e);
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return (
            <Stack horizontal className={styles.questionInputContainer}>
                <TextField
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "auto", justifyContent: "bottom" }}>
                        <Tooltip content="Ask question button" relationship="label">
                            <Button
                                size="large"
                                icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                                disabled={sendQuestionDisabled}
                                onClick={sendQuestion}
                            />
                        </Tooltip>
                        <Tooltip content="Speech recon not supported by browser." relationship="label">
                            <Button size="large" icon={<Mic28Filled primaryFill="rgba(115, 118, 225, 1)" />} disabled={true} onClick={getVoiceInput} />
                        </Tooltip>
                        <Tooltip content="upload file(s)" relationship="label">
                            <div>
                                <input
                                    onChange={setFilesArray}
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    ref={clickRef}
                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*, .csv, .txt, .pdf, .json, .docx, .xlsx"
                                    type="file"
                                />
                                <Button size="large" icon={<AddCircle28Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={getFileinput} />
                                <div>{waiting === true ? <h1>In Progress...</h1> : <h1>Uploaded</h1>}</div>
                            </div>
                        </Tooltip>
                        {waiting ? (
                            <div style={{ display: "flex", alignItems: "center" }} className="loaderHolder">
                                <img width={"20px"} height={"20px"} style={{}} src={Loader} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </Stack>
        );
    }

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            <div className={styles.questionInputButtonsContainer}>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "auto", justifyContent: "bottom" }}>
                    <Tooltip content="Ask question button" relationship="label">
                        <Button
                            size="large"
                            icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                        />
                    </Tooltip>
                    {listenMode ? (
                        <Tooltip content="Listening..." relationship="label">
                            <Button size="large" icon={<MoreCircle28Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={stopListening} />
                        </Tooltip>
                    ) : (
                        <Tooltip content="Use your voice" relationship="label">
                            <Button size="large" icon={<Mic28Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={getVoiceInput} />
                        </Tooltip>
                    )}
                    <Tooltip content="upload file(s)" relationship="label">
                        <div>
                            <input
                                onChange={setFilesArray}
                                id="fileInput"
                                style={{ display: "none" }}
                                ref={clickRef}
                                type="file"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*, .csv, .txt, .pdf, .json, .docx, .xlsx"
                            />
                            <Button size="large" icon={<AddCircle28Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={() => setIsModalOpen(true)} />
                            <UploadDialogModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
                        </div>
                    </Tooltip>
                    {waiting ? (
                        <div style={{ display: "flex", alignItems: "center" }} className="loaderHolder">
                            <img width={"20px"} height={"20px"} style={{}} src={Loader} />
                            <div style={{ fontFamily: "sans-serif" }}>Uploading...</div>
                        </div>
                    ) : null}
                </div>
            </div>
            {/* <Button onClick={onStartConversation}>{convoButtonName}</Button> */}
        </Stack>
    );
};
