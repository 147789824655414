import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest } from "./models";
import { useLogin } from "../authConfig";
import { Hash } from "react-router-dom";

const BACKEND_URI = import.meta.env.VITE_BACKEND_URI != null ? import.meta.env.VITE_BACKEND_URI : window.location.origin
const PROJECT_ID = import.meta.env.VITE_PROJECT_ID

export var CONFIGURATION =  {
    Name: "",
    Logo: "",
    ProjectID: "",
    Username: "",
    Questions: []
};

function isAuthenticated(){
    return window.localStorage.getItem('RYZE_TOKEN')
}
export async function initializeSettings(): Promise<boolean> {
    return new Promise(async (resolver) =>{
        const response = await fetch(`${BACKEND_URI}/settings`)
        const data = await response.json();
        CONFIGURATION.Name = data.name;
        CONFIGURATION.Logo = data.logo;
        CONFIGURATION.ProjectID = data.projectId;
        CONFIGURATION.Username = ""
        CONFIGURATION.Questions = data.questions;
        resolver(true)
    })
    
}
export function getUploadSettings(){
    let ryze_user = window.localStorage.getItem('RYZE_USER')
    if(ryze_user){
        let ryze_user_json = JSON.parse(ryze_user)
        CONFIGURATION.Username = ryze_user_json.email
    }
    return CONFIGURATION;
}
function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    //if (useLogin) {
        const token = isAuthenticated()
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
    //}

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    console.log(BACKEND_URI);
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/?projectId=${CONFIGURATION.ProjectID}&file=${citation}`;
}

export function playResponse(citation: string): void {
    generateHash(citation).then(hashedAudio => {
        var audio = new Audio();
        const hasAudio = playedAudios.has(hashedAudio);
        if (hasAudio) {
            const audiob64 = playedAudios.get(hashedAudio);
            audio.src = "data:audio/mpeg;base64," + audiob64;
            audio.play();
        } else {
            fetch(`${BACKEND_URI}/tasks/text-to-speech/run`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    prompt: citation
                })
            })
                .then(reponse => {
                    reponse.json().then(body => {
                        const speechb64 = body.response;
                        audio.src = "data:audio/mpeg;base64," + speechb64;
                        audio.play();
                        playedAudios.set(hashedAudio, speechb64);
                    });
                })
                .catch(ex => {});
        }
    });
}
const playedAudios: Map<String, Object> = new Map<String, Object>();
function generateHash(inputString: string) {
    // Encode the string into a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);

    // Generate the hash
    return crypto.subtle.digest("SHA-256", data).then(hashBuffer => {
        // Convert the ArrayBuffer to a hex string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
        return hashHex;
    });
}
