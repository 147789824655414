import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { createDarkTheme, createLightTheme } from "@fluentui/react-components";
import type { BrandVariants, Theme } from "@fluentui/react-components";
import { initializeSettings } from "./api";

const ryzeai: BrandVariants = {
    10: "#030205",
    20: "#161523",
    30: "#23213E",
    40: "#2D2B55",
    50: "#38366D",
    60: "#424186",
    70: "#4D4DA0",
    80: "#5858BB",
    90: "#6364D7",
    100: "#6E70F3",
    110: "#7F7FFF",
    120: "#938FFF",
    130: "#A69FFF",
    140: "#B7AFFF",
    150: "#C7C0FF",
    160: "#D7D1FF"
};

const lightTheme: Theme = {
    ...createLightTheme(ryzeai)
};

const darkTheme: Theme = {
    ...createDarkTheme(ryzeai)
};

darkTheme.colorBrandForeground1 = ryzeai[110]; // use brand[110] instead of brand[100]
darkTheme.colorBrandForeground2 = ryzeai[120]; // use brand[120] instead of brand[110]

import "regenerator-runtime";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import Login from "./pages/login/Login";

var layout;

layout = <Layout lightTheme={lightTheme} darkTheme={darkTheme} />;

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            {
                index: true,
                element: <Login />
            },
            {
                path: "login",
                element: <Login />
            },
            {
                path: "chat",
                element: <Chat />
            },
            {
                path: "qa",
                lazy: () => import("./pages/oneshot/OneShot")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    
    <React.StrictMode>
       <RouterProvider router={router} />
   </React.StrictMode>
    
    
);
export default lightTheme