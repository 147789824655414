// useMessages.ts
import { useState, useEffect, useCallback } from "react";
import { MessageBarType } from "@fluentui/react";

// Define the type for a single message
export interface Message {
    id: number;
    message: string;
    messageBarType: MessageBarType;
}

export const useMessages = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const addMessage = useCallback((newMessage: Omit<Message, "id">) => {
        setMessages(prevMessages => [...prevMessages, { ...newMessage, id: prevMessages.length }]);
    }, []);

    // Function to simulate fetching new messages
    //   const fetchMessages = () => {
    //     const newMessage: Message = {
    //       id: messages.length,
    //       message: `Message number ${messages.length + 1}`,
    //       messageBarType: MessageBarType.info, // This can be dynamic based on your needs
    //     };
    //     setMessages((prevMessages) => [...prevMessages, newMessage]);
    //   };

    //   useEffect(() => {
    //     const intervalId = setInterval(() => {
    //       // Example of adding a message internally
    //       addMessage({
    //         message: `Automatic message ${messages.length + 1}`,
    //         messageBarType: MessageBarType.info,
    //       });
    //     }, 5000); // Fetch new message every 5 seconds

    //     return () => clearInterval(intervalId);
    //   }, [addMessage, messages.length]);

    return { messages, addMessage };
};
